<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col cols="7">
            <label>
              1 Декларация
              <div class="d-flex">
                <label>
                  <v-text-field
                    ref="declaration_kind_code"
                    :value="type_declaration.declaration_kind_code"
                    outlined
                    hide-details="auto"
                    dense
                    disabled
                    background-color="grey lighten-2"
                    class="mr-1 p-0"
                  />
                </label>
                <label>
                  <v-autocomplete
                    ref="transit_feature_code"
                    v-model="type_declaration.transit_feature_code"
                    :items="transit_features"
                    auto-select-first
                    outlined
                    hide-details="auto"
                    dense
                    background-color="white"
                    class="mr-1 p-0"
                  />
                </label>
                <label>
                  <v-autocomplete
                    ref="transit_procedure"
                    v-model="type_declaration.transit_procedure"
                    :items="transit_procedures"
                    auto-select-first
                    outlined
                    hide-details="auto"
                    dense
                    background-color="white"
                    class="mr-1 p-0"
                  />
                </label>
                <label class="ml-6">
                  <v-autocomplete
                    ref="el_doc_indicator"
                    v-model="type_declaration.el_doc_indicator"
                    :items="el_doc_indicators"
                    auto-select-first
                    outlined
                    hide-details="auto"
                    dense
                    background-color="white"
                    class="mr-1 p-0"
                  />
                </label>
              </div>
            </label>
          </v-col>
          <v-col class="px-0 pl-6">
            <label>
              3 Формы
              <div class="d-flex">
                <v-text-field
                  class="mr-1 p-0"
                  outlined
                  dense
                  hide-details="auto"
                  disabled
                  value="1"
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="3"
          >
            <label>
              Спецификации
              <div class="d-flex">
                <v-text-field
                  ref="specification"
                  v-model="type_declaration.specification"
                  outlined
                  background-color="white"
                  dense
                  hide-details="auto"
                />
              </div>
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col
        cols="6"
      >
        <div class="statistics-box d-flex">
          <v-col
            cols="5"
          >
            <label>
              А Регистрационный номер
              <field-menu>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                  <v-list-item
                    @click="fillWithCustomsData"
                  >Заполнить данными из таможни</v-list-item>
                  <v-list-item
                    @click="clearRegNumA"
                  >Очистить регистрационный номер и сменить ID документа</v-list-item>
                </v-list>
              </field-menu>
              <v-text-field
                ref="reg_number"
                v-model="registration_details.reg_num_a"
                v-mask="'########/######/#######'"
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                class="p-0"
              />
            </label>
          </v-col>
          <v-col
            cols="7"
          >
            <label>
              C Орган отправления
              <v-autocomplete
                ref="dispatch_customs_code"
                v-model="registration_details.dispatch_customs_code"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                :disabled="disabled"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                item-text="search"
                item-value="code"
                :items="customsPoints"
                class="p-0"
              />
            </label>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {eventBus} from "@/bus.js";
import {
  type_declaration as onTypeDeclaration,
  registration_details as onRegistrationDetails,
} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import {getCustomsNameByCode} from "@/helpers/catalogs";
import FieldMenu from "@/components/documents/field-menu.vue";

export default {
  components: {FieldMenu},
  mixins: [blockAutoUpdate, blockVisibility],
  data() {
    return {
      date: null, // времменая дата
      block_id: "transit-main-info",
      transit_features: ["", "ФЛ"],
      transit_procedures: ["ТР", "ИМ", "ЭК", "ВТ", "ТС"],
      el_doc_indicators: ["ЭД", "ОО"],
      disabled: true,
      type_declaration: {
        declaration_id: null,
        declaration_kind_code: "ТТ",
        el_doc_indicator: "ЭД",
        specification: "",
        transit_feature_code: "",
        transit_procedure: "",
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: "",
        seal_numbers: "",
        dispatch_customs_code:"",
        dispatch_customs_name:""
      },
    };
  },
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      customsPoints: 'catalogs/getNsiCustomsPoints',
    }),
  },
  watch: {
    selected: {
      handler(){
        this.setFields();
        this.disabled = true
      },
      deep: true
    },
  },
  created() {
    onTypeDeclaration.subscribe(this.onControl);
    onRegistrationDetails.subscribe(this.onControl);
  },
  beforeDestroy() {
    onTypeDeclaration.unsubscribe();
    onRegistrationDetails.unsubscribe();
  },
  methods: {
    highlightField,
    onControl({path}){
      highlightField.call(this, {path})
    },
    setFields() {
      const {type_declaration} = this.selected || {};
      const {registration_details} = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    clearCountryOnProcedureChange(initial, updated) {
      if(initial !== updated && ["ВТ", "ИМ"].includes(updated)){
        eventBus.$emit("clearCountryTd");
      }
    },
    async uploadData() {
      const initialTransitProcedure = this.selected.type_declaration.transit_procedure
      const newTransitProcedure = this.type_declaration.transit_procedure

      const type_declaration = this.convertEmptyStringsToNull(
        this.type_declaration
      );
      const registration_details = this.convertEmptyStringsToNull({
        ...this.registration_details,
        dispatch_customs_name: getCustomsNameByCode(this.customsPoints, this.registration_details.dispatch_customs_code)
      });

      const typeDeclarationResponse = await this.$store.dispatch("transit/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });
      await this.$store.dispatch("transit/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
      this.disabled = true

      this.clearCountryOnProcedureChange(initialTransitProcedure, newTransitProcedure)
      
      return Promise.resolve(typeDeclarationResponse);
    },
  },
};
</script>
<style scoped>
  label{
    margin: 0;
  }
</style>
